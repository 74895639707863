<template>
    <div>
      <img :style="styleObj" :class="className" :src="`${url}${imgName}.png`" >
    </div>
  </template>
  <script>
  export default {
    props: {
      imgName: String,
      className: String,
      styleObj: Object
    },
    data() {
      return {
        url: 'https://dotron.cn:9000/dtron/'
      };
    },
    mounted() {},
    methods: {},
  };
  </script>
  
  <style scoped>

  </style>
  