
// import Home from '../views/Home.vue'
// import { UserLayout } from '@/components/layouts'
// import TabLayout from '@/views/main/Index'

/**
 * 走菜单，走权限控制
 * @type {[null,null]}
 */
export const asyncRouterMap = [
  {
    path: '*',
    component: () => import('@/views/exception/404')
  }
  //   {
  //     path: '/',
  //     component: TabLayout,
  //     meta: { title: '首页' },
  //     redirect: '/Home',
  //     children: [
  //   {
  //     path: 'Home',
  //     name: 'Home',
  //     component: Home,
  //   },
  //   {
  //     path: 'integralrecharge',
  //     name: 'integralRecharge',
  //     component: () => import('../views/integralRecharge.vue')
  //   },
  //   {
  //     path: 'storeMenu',
  //     name: 'storeMenu',
  //     component: () => import('../views/store/storeMenu.vue')
  //   },
  //   {
  //     path: 'store',
  //     name: 'store',
  //     component: () => import('../views/store/store.vue'),
  //   },
  //   {
  //     path: 'classRoom',
  //     name: 'classRoom',
  //     component: () => import('../views/classRoom/classRoom.vue'),
  //   },
  //   {
  //     path: 'goodsManage',
  //     name: 'goodsManage',
  //     component: () => import('../views/store/goodsManage.vue'),
  //   },
  //   {
  //     path: 'studentInfo',
  //     name: 'studentInfo',
  //     component: () => import('../views/studentInfo.vue')
  //   }
  // ]}
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/pc',
    component: () => import('@/views/main/Index'),
    meta: { title: '首页' },
    redirect: '/pc/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('../views/contentList/home/index.vue'),
      },
      {
        path: 'case',
        name: 'case',
        component: () => import('../views/contentList/case/index.vue'),
      },
      {
        path: 'aboutUs',
        name: 'aboutUs',
        component: () => import('../views/contentList/aboutUs/index.vue'),
      }
    ]
  },
  {
    path: '/mobile',
    component: () => import('@/views/main/IndexMobile'),
    meta: { title: '首页' },
    redirect: '/mobile/home',
    children: [
      {
        path: 'home',
        name: 'Mhome',
        component: () => import('../views/MContentList/home/index.vue'),
      },
      {
        path: 'about',
        name: 'Mabout',
        component: () => import('../views/MContentList/about/index.vue'),
      },
      {
        path: 'case',
        name: 'Mcase',
        component: () => import('../views/MContentList/case/index.vue'),
      }
    ]
  },
  {
    path: '*',
    component: () => import('@/views/exception/404')
  }
]
