import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Storage from 'vue-ls'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import config from '@/defaultSettings'
import VueTouchKeyboard from "vue-touch-keyboard";
import '@/permission' // permission control
import 'lib-flexible'
import animated from 'animate.css' // npm install animate.css --save安装，在引入
import ImgItem from '@/components/global/imgItem'

Vue.component('ImgItem', ImgItem)
Vue.use(animated)
// import { Icon } from 'ant-design-vue';
// const IconFont = Icon.createFromIconfontCN({
//   scriptUrl: require("@/assets/iconfont/iconfont.js").default
// });
 // load default style
 import { Collapse, CollapseItem,Icon,Swipe, SwipeItem,Form,Field,Button,Popup  } from 'vant';

 Vue.use(Collapse);
 Vue.use(CollapseItem);
 Vue.use(Icon);
 Vue.use(Swipe);
 Vue.use(SwipeItem);
 Vue.use(Form);
 Vue.use(Field);
 Vue.use(Button);
 Vue.use(Popup);
 import {
  ACCESS_TOKEN
} from "@/store/mutation-types"
// Vue.component('icon-font', IconFont)
Vue.use(Storage, config.storageOptions)
Vue.use(VueTouchKeyboard);
Vue.use(Antd);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  mounted () {
    store.commit('SET_TOKEN', Vue.ls.get(ACCESS_TOKEN))
  },
  render: h => h(App)
}).$mount('#app')
