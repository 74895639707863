import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'
import permission from './modules/permission'
import enhance from './modules/enhance'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    permission,
    enhance
  },
  state: {
    path: '',
    openId : '',
    classInfo : {},
    userEvaluate: [],
    evaluateNum: 0,
  },
  mutations: {
    setPathName(state, name) {
      state.path = name;
    },
    setOpenIdName(state, name) {
      state.openId = name;
    },
    setClassInfo(state, info) {
      state.classInfo = info;
    },
    setUserEvaluate(state, data){
      state.userEvaluate = data;
    },
    setEvaluateNum(state, num){
      state.evaluateNum = num;
    }
  },
  actions: {
    setClassInfo(context, info) {
      context.commit('setClassInfo', info);
    },
    setPathName(context, name) {
      context.commit('setPathName', name);
    },
    setOpenIdName(context, name) {
      context.commit('setOpenIdName', name);
    },
    setUserEvaluate(context, data) {
      context.commit('setUserEvaluate', data);
    },
    setEvaluateNum(context, num) {
      context.commit('setEvaluateNum', num);
    }
  },
  getters
})
