import Vue from 'vue'
import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { ACCESS_TOKEN,INDEX_MAIN_PAGE_PATH } from '@/store/mutation-types'
// import { generateIndexRouter } from "@/utils/util"

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login','/Home','/classRoom','/loginCode','/classDetails'] // no redirect whitelist

router.beforeEach((to, from, next) => {
  // NProgress.start() // start progress bar
  // store.dispatch('setPathName',to.path)
  // console.log(to.path)
  // if (Vue.ls.get(ACCESS_TOKEN)) {
  //   /* has token */
  //   if (to.path === '/login') {
  //     next({ path: INDEX_MAIN_PAGE_PATH })
  //     NProgress.done()
  //   } else {
  //     next()
  //   }
  // } else {
  //   if (whiteList.indexOf(to.path) !== -1) {
  //     // 在免登录白名单，直接进入
      
  //     next()
  //     NProgress.done()
  //   } else {
  //     next({ path: '/login' })
  //     NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
  //   }
  // }
  
  next()
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
