<template>
  <a-config-provider :locale="zh_CN">
    <div id="app" class="Regular">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

moment.locale("zh-cn");
export default {
  data() {
    return {
      zh_CN,
    };
  },
  mounted() {
    if (this.isMobile()) {
      this.$router.push("/mobile");
    } else {
      this.$router.push("/pc");
    }
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        // match方法可在字符串内检索指定的值，
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      // console.log(flag)
      return flag;
    },
  },
};
</script>
<style>
@import url("assets/font-family/font.css");
.Bold {
  font-family: Alibaba-PuHuiTi-Bold;
}
.Medium {
  font-family: Alibaba-PuHuiTi-Medium;
}
.Heavy {
  font-family: Alibaba-PuHuiTi-Heavy;
}
.Light {
  font-family: Alibaba-PuHuiTi-Light;
}
.Regular {
  font-family: Alibaba-PuHuiTi-Regular;
}
#app {
  height: 100%;
}
</style>